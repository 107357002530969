// Pinia Store
import { defineStore } from 'pinia'
import { useCustomFetch } from '~/composable/useCustomFetch.js'
export const useAuthStore = defineStore('authUser', {
  // convert to a function
  state: () => ({
    authUser: null,
  }),
  actions: {
    // no context as first argument, use `this` instead
    login({ email, password }) {
      return new Promise(async (resolve, reject) => {
        const { data, error } = await useCustomFetch('/adminauth/login', { method: 'POST', body: { email, password } })
        if (error.value) {
          const data = error.value
          reject(data)
        } else {
          const authUser = {
            ...data.value
          }
          this.authUser = authUser;
          const { token } = authUser
          localStorage.setItem('token', token)
          resolve(authUser)
        }
      })
    },
    register({ email, password }) {
      return new Promise(async (resolve, reject) => {
        const { data, error } = await useCustomFetch('/adminauth/register', { method: 'POST', body: { email, password } })
        if (error.value) {
          const data = error.value
          reject(data)
        } else {
          const authUser = {
            ...data.value
          }
          this.authUser = authUser;
          const { token } = authUser
          localStorage.setItem('token', token)
          resolve(authUser)
        }
      })
    },
    logout() {
      this.authUser = null;
      localStorage.removeItem('token')
    },
    verifyToken() {
      return new Promise(async (resolve, reject) => {
        const { data, error } = await useCustomFetch('/auth/admin/me?populate[]=location', { method: 'GET' })
        if (error.value) {
          const data = error.value
          reject(data)
        } else {
          const authUser = {
            ...data.value
          }
          this.authUser = authUser;
          resolve(authUser)
        }
      })
    },
    changePassword(reqObj) {
      return new Promise(async (resolve, reject) => {
        const { data, error } = await useCustomFetch('/auth/admin/changePassword', { method: 'POST', body: reqObj })
        if (error.value) {
          const data = error.value
          reject(data)
        }
        else {
          resolve(data.value)
        }
      })
    },
  },
})