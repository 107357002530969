import { default as _404lZJpwxvtooMeta } from "/vercel/path0/pages/404.vue?macro=true";
import { default as indexG7R2Mya2P4Meta } from "/vercel/path0/pages/admin/index.vue?macro=true";
import { default as indexE9M0F0TuqEMeta } from "/vercel/path0/pages/auth0/callback/index.vue?macro=true";
import { default as index98lWnPghHgMeta } from "/vercel/path0/pages/auth0/logout/index.vue?macro=true";
import { default as _91id_93oCuKlYLwwoMeta } from "/vercel/path0/pages/doctor/[id].vue?macro=true";
import { default as index1TH53ech1KMeta } from "/vercel/path0/pages/doctor/index.vue?macro=true";
import { default as _91id_93dlIB2OB7E8Meta } from "/vercel/path0/pages/email-templates/[id].vue?macro=true";
import { default as add_45newpR0hgoyXjpMeta } from "/vercel/path0/pages/email-templates/add-new.vue?macro=true";
import { default as indexjGknB9GrN1Meta } from "/vercel/path0/pages/email-templates/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as _91id_93eAcTDECsnwMeta } from "/vercel/path0/pages/payee/[id].vue?macro=true";
import { default as index3ASPGM05jmMeta } from "/vercel/path0/pages/payee/index.vue?macro=true";
import { default as _91id_93n9xpVhSWgyMeta } from "/vercel/path0/pages/rebate-hoi/[id].vue?macro=true";
import { default as indexvc4jdCqi5fMeta } from "/vercel/path0/pages/rebate-hoi/index.vue?macro=true";
import { default as _91id_93Ivho12jcRmMeta } from "/vercel/path0/pages/rebate/[id].vue?macro=true";
import { default as indexThaVx51mPTMeta } from "/vercel/path0/pages/rebate/index.vue?macro=true";
import { default as _91month_93zIBYC0HXkNMeta } from "/vercel/path0/pages/report/[month].vue?macro=true";
import { default as indexAzUOXnxcxeMeta } from "/vercel/path0/pages/report/index.vue?macro=true";
export default [
  {
    name: _404lZJpwxvtooMeta?.name ?? "404",
    path: _404lZJpwxvtooMeta?.path ?? "/404",
    meta: _404lZJpwxvtooMeta || {},
    alias: _404lZJpwxvtooMeta?.alias || [],
    redirect: _404lZJpwxvtooMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/404.vue").then(m => m.default || m)
  },
  {
    name: indexG7R2Mya2P4Meta?.name ?? "admin",
    path: indexG7R2Mya2P4Meta?.path ?? "/admin",
    meta: indexG7R2Mya2P4Meta || {},
    alias: indexG7R2Mya2P4Meta?.alias || [],
    redirect: indexG7R2Mya2P4Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexE9M0F0TuqEMeta?.name ?? "auth0-callback",
    path: indexE9M0F0TuqEMeta?.path ?? "/auth0/callback",
    meta: indexE9M0F0TuqEMeta || {},
    alias: indexE9M0F0TuqEMeta?.alias || [],
    redirect: indexE9M0F0TuqEMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth0/callback/index.vue").then(m => m.default || m)
  },
  {
    name: index98lWnPghHgMeta?.name ?? "auth0-logout",
    path: index98lWnPghHgMeta?.path ?? "/auth0/logout",
    meta: index98lWnPghHgMeta || {},
    alias: index98lWnPghHgMeta?.alias || [],
    redirect: index98lWnPghHgMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/auth0/logout/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93oCuKlYLwwoMeta?.name ?? "doctor-id",
    path: _91id_93oCuKlYLwwoMeta?.path ?? "/doctor/:id()",
    meta: _91id_93oCuKlYLwwoMeta || {},
    alias: _91id_93oCuKlYLwwoMeta?.alias || [],
    redirect: _91id_93oCuKlYLwwoMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/doctor/[id].vue").then(m => m.default || m)
  },
  {
    name: index1TH53ech1KMeta?.name ?? "doctor",
    path: index1TH53ech1KMeta?.path ?? "/doctor",
    meta: index1TH53ech1KMeta || {},
    alias: index1TH53ech1KMeta?.alias || [],
    redirect: index1TH53ech1KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/doctor/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dlIB2OB7E8Meta?.name ?? "email-templates-id",
    path: _91id_93dlIB2OB7E8Meta?.path ?? "/email-templates/:id()",
    meta: _91id_93dlIB2OB7E8Meta || {},
    alias: _91id_93dlIB2OB7E8Meta?.alias || [],
    redirect: _91id_93dlIB2OB7E8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/email-templates/[id].vue").then(m => m.default || m)
  },
  {
    name: add_45newpR0hgoyXjpMeta?.name ?? "email-templates-add-new",
    path: add_45newpR0hgoyXjpMeta?.path ?? "/email-templates/add-new",
    meta: add_45newpR0hgoyXjpMeta || {},
    alias: add_45newpR0hgoyXjpMeta?.alias || [],
    redirect: add_45newpR0hgoyXjpMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/email-templates/add-new.vue").then(m => m.default || m)
  },
  {
    name: indexjGknB9GrN1Meta?.name ?? "email-templates",
    path: indexjGknB9GrN1Meta?.path ?? "/email-templates",
    meta: indexjGknB9GrN1Meta || {},
    alias: indexjGknB9GrN1Meta?.alias || [],
    redirect: indexjGknB9GrN1Meta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/email-templates/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexoOBDY93e9EMeta?.name ?? "login",
    path: indexoOBDY93e9EMeta?.path ?? "/login",
    meta: indexoOBDY93e9EMeta || {},
    alias: indexoOBDY93e9EMeta?.alias || [],
    redirect: indexoOBDY93e9EMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93eAcTDECsnwMeta?.name ?? "payee-id",
    path: _91id_93eAcTDECsnwMeta?.path ?? "/payee/:id()",
    meta: _91id_93eAcTDECsnwMeta || {},
    alias: _91id_93eAcTDECsnwMeta?.alias || [],
    redirect: _91id_93eAcTDECsnwMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payee/[id].vue").then(m => m.default || m)
  },
  {
    name: index3ASPGM05jmMeta?.name ?? "payee",
    path: index3ASPGM05jmMeta?.path ?? "/payee",
    meta: index3ASPGM05jmMeta || {},
    alias: index3ASPGM05jmMeta?.alias || [],
    redirect: index3ASPGM05jmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/payee/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93n9xpVhSWgyMeta?.name ?? "rebate-hoi-id",
    path: _91id_93n9xpVhSWgyMeta?.path ?? "/rebate-hoi/:id()",
    meta: _91id_93n9xpVhSWgyMeta || {},
    alias: _91id_93n9xpVhSWgyMeta?.alias || [],
    redirect: _91id_93n9xpVhSWgyMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/rebate-hoi/[id].vue").then(m => m.default || m)
  },
  {
    name: indexvc4jdCqi5fMeta?.name ?? "rebate-hoi",
    path: indexvc4jdCqi5fMeta?.path ?? "/rebate-hoi",
    meta: indexvc4jdCqi5fMeta || {},
    alias: indexvc4jdCqi5fMeta?.alias || [],
    redirect: indexvc4jdCqi5fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/rebate-hoi/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Ivho12jcRmMeta?.name ?? "rebate-id",
    path: _91id_93Ivho12jcRmMeta?.path ?? "/rebate/:id()",
    meta: _91id_93Ivho12jcRmMeta || {},
    alias: _91id_93Ivho12jcRmMeta?.alias || [],
    redirect: _91id_93Ivho12jcRmMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/rebate/[id].vue").then(m => m.default || m)
  },
  {
    name: indexThaVx51mPTMeta?.name ?? "rebate",
    path: indexThaVx51mPTMeta?.path ?? "/rebate",
    meta: indexThaVx51mPTMeta || {},
    alias: indexThaVx51mPTMeta?.alias || [],
    redirect: indexThaVx51mPTMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/rebate/index.vue").then(m => m.default || m)
  },
  {
    name: _91month_93zIBYC0HXkNMeta?.name ?? "report-month",
    path: _91month_93zIBYC0HXkNMeta?.path ?? "/report/:month()",
    meta: _91month_93zIBYC0HXkNMeta || {},
    alias: _91month_93zIBYC0HXkNMeta?.alias || [],
    redirect: _91month_93zIBYC0HXkNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/[month].vue").then(m => m.default || m)
  },
  {
    name: indexAzUOXnxcxeMeta?.name ?? "report",
    path: indexAzUOXnxcxeMeta?.path ?? "/report",
    meta: indexAzUOXnxcxeMeta || {},
    alias: indexAzUOXnxcxeMeta?.alias || [],
    redirect: indexAzUOXnxcxeMeta?.redirect || undefined,
    component: () => import("/vercel/path0/pages/report/index.vue").then(m => m.default || m)
  }
]