import { useAuth0 } from "~/services/auth0.service";
import { useAuthStore } from "~/stores/useAuthStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
  const app = useNuxtApp()
  const authStore = useAuthStore()
  const auth0Client = await useAuth0()
  const { verifyToken } = authStore

  let isLogInPage = false
  let list = ['/login', '/auth0/callback']
  list.includes(to.fullPath)
  for (const path of list) {
    if (to.fullPath.includes(path)) {
      isLogInPage = true
      break
    }
  }
  try {
    const isAuthenticated = await auth0Client.isAuthenticated();
    if (!isAuthenticated) {
      if (isLogInPage) {
        return;
      }
      return navigateTo("/login");
    } else {
      await verifyToken()
      if (isLogInPage) {
        return navigateTo("/");
      }
      return;
    }
  } catch (error) {
    console.log('error', error)
    if (isLogInPage) {
      return;
    }
    return navigateTo("/login");
  }

})